.account-detail {
    .deshboard-menu {
        background-color: $white;
        margin: 50px 0;
        border-radius: 5px;

        ul {
            display: flex;

            @media #{$vpsmmobile} {
                flex-direction: column;
            }

            li {
                @media #{$vpsmmobile} {
                    text-align: center;
                    border-bottom: 1px solid $cGray-sty1;
                }


                a {
                    font-size: 18px;
                    color: $black;
                    padding: 10px 50px;
                    display: block;

                    @media #{$vptabletP} {
                        padding: 10px 25px;
                    }
    
                    @media #{$vpmobile} {
                        padding: 10px 16px;
                        font-size: 15px;
                    }
                }

                &:hover {
                    background-color: $cPrimary;
                    transition: all .35s ease;

                    a {
                        color: $white;
                    }
                }

                &:first-child {
                    border-radius: 5px 0 0 5px;
                }

                &:last-child {
                    margin-left: auto;
                    background-color: $cPrimary;
                    border-radius: 0 5px 5px 0;

                    @media #{$vpsmmobile} {
                        margin: 20px auto;
                    }

                    a {
                        color: $white;
                    }
                }

                &.active {
                    background-color: $cPrimary;

                    a {
                        color: $white;
                    }
                }
            }
        }
    }

    .account-detail-section {
        background-color: $white;
        padding: 30px;
        border-radius: 5px;

        .form-group {
            p {
                margin-bottom: 10px;
            }

            .added {
                display: flex;
                justify-content: space-between;

                .btn {
                    &.btn-primary {
                        font-size: 18px;
                        font-weight: 500;
                        text-transform: initial;
                        width: 123px;
                        max-width: 100%;

                        @media #{$vpsmmobile} {
                            width: 100%;
                        }
                    }

                }
            }
        }
    }

    .registeraion-radio {
        .select-file {
            background-color: $cGray;
            padding: 3px 25px;
            margin-right: 10px;
            cursor: pointer;
            transition: all 0.35s ease;

            &:hover {
                background-color: $carousel-btn-bg;
                color: $white;
            }
        }

        .showname {
            i {
                font-style: normal;
            }
        }
    }
}