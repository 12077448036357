.thumbnail {
    padding: 30px 0;
}

.owl-carousel .owl-stage-outer {
    width: 100%;
}

.owl-item {
    width: 100%;
}

.postBox {
    margin-bottom: 30px;

    &.mar-bottom {
        margin-bottom: 85px;

        @media #{$vpsmmobile} {
            margin-bottom: 30px;
        }
    }

    .postImg {
        margin-bottom: 50px;
        border-radius: 12px;
        margin-bottom: 55px;
        position: relative;
        text-align: center;

        &.mar-top {
            @media (max-width:767px) {
                margin-top: 30px;
            }

        }

        a {
            display: block;
        }

        .post-date {
            position: absolute;
            top: 20px;
            left: 20px;
            background-color: $cPrimary;
            padding: 0 20px;
            color: $white;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 400;
        }

        img {
            border-radius: 12px;
            height: 100%;
            width: 100%;
            object-fit: cover;

            &.width-auto {
                width: auto;
            }
        }

        .post-overlay {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black, .5);
            border-radius: 12px;
            transition: all .35s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;

            .tosvg {
                width: 51px;
                height: 51px;

                path {
                    fill: $white;
                }
            }

            &.show-overlay {
                opacity: 1;
                visibility: visible;
                pointer-events: inherit;
            }
        }

        &.no-mg {
            margin-bottom: 0;
        }

        @media(max-width:991px) {
            margin-bottom: 35px;
        }

        @media(max-width:767px) {
            margin-bottom: 25px;
        }

        &.service-oldtime {
            height: 100%;
        }

        .service-icons {
            width: 104px;
            height: 103px;
        }

    }

    .postDes {
        &.contact-detail {
            display: flex;

            .contact-detail-img {
                width: 80px;
            }

            .contact-detail-content {
                width: calc(100% - 80px);
                margin: 20px 0 0 10px;

                p {
                    text-align: left;
                    font-size: 18px;
                    line-height: 28px;
                    margin-top: 14px;
                }
            }
        }

        h3 {
            text-align: center;
            margin-bottom: 24px;

            a {
                color: $black;
            }
        }

        p {
            text-align: center;
            margin-bottom: 0;

            &.cutomerDes {
                font-size: 14px;
                text-align: left;
                margin-bottom: 30px;
            }
        }
    }

    &.sty-4 {
        .postImg {
            img {
                min-height: 450px;

                @media #{$vpsmmobile} {
                    min-height: 325px;
                }
            }
        }
    }

    .custom-btn {

        &.mg-top {
            margin-top: 60px;
        }
    }

    &.sty-1 {
        .postImg {
            margin-bottom: 60px;
        }
    }

    &.sty-2 {
        display: flex;
        margin: 0 auto;

        @media #{$vptabletP} {
            flex-direction: column;
        }

        .postImg {
            min-width: 210px;
            border-radius: 50%;
            margin-right: 44px;

            @media #{$vptabletP} {
                margin-right: 0;
            }

            img {
                border-radius: 50%;
                width: auto;
                margin: 0 auto;
            }
        }

        .postDes {
            h4 {
                @media #{$vptabletP} {
                    text-align: center;
                }
            }

            .cutomerDes {
                @media #{$vptabletP} {
                    text-align: center;
                }
            }
        }
    }

    &.sty-3 {
        background-color: $white;

        .postDes {
            padding: 20px 0;
            height: 120px;

            .contact-box-img {
                text-align: center;
            }

            .contact-box-heading {
                margin: 12px 0 5px;
                padding: 0 10px;

                h4 {
                    text-align: center;
                    font-size: 14px;
                }
            }

            .contact-box-para {
                text-align: center;
                padding: 0 10px;

                a,
                span {
                    font-size: 12px;
                    line-height: 18px;
                    text-decoration: none;
                    color: $cCharcoal;
                    font-weight: 400;
                }

                a {
                    &:hover {
                        color: $cPrimary;
                    }
                }
            }
        }
    }

    &.sty-4 {
        background-color: $white;
        height: 120px;
        align-items: center;
        display: flex;
        justify-content: center;

        .postDes {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .contact-box-img {
                text-align: center;

                .tosvg {
                    height: 30px;
                }
            }

            .contact-box-heading {
                margin: 20px 0 5px 0;

                p {
                    line-height: 18px;
                    padding: 0 7px;
                    text-align: center;
                    font-size: 14px;
                }

                h4 {
                    text-align: center;
                    font-size: 18px;

                    @media #{$vptabletL} {
                        font-size: 13px;
                    }
                }
            }
        }

        @media (max-width:991px){
            height: 100px;
            .postDes {
                .contact-box-heading {
                    margin-top: 10px;
                }
            }
        }

        @media (max-width:767px){
            height: auto;
            justify-content: flex-start;
            padding: 12px 8px;
            .postDes {
                flex-direction: row;
                justify-content: flex-start;
                .contact-box-img {
                    .tosvg {
                        width: 65px;
                    }
                }
                .contact-box-heading {
                    margin: 0 0 0 10px;
                    p{
                        padding: 0;
                        text-align: left;
                    }
                }
            }
        }
    }

    &:hover {
        .postImg {
            .post-overlay {
                opacity: 1;
                visibility: visible;
                pointer-events: inherit;
            }
        }
    }
}

.margin-top {
    margin-top: 50px;
}

.styleBox-1 {
    margin-bottom: 70px;

    @media #{$vpsmmobile} {
        margin-bottom: 30px;
    }
}

.small-container {
    width: 690px;
    margin: 0 auto;

    @media #{$vpmobile} {
        width: 100%;
    }
}

.car-filteration {
    background-color: $cGray;
    margin-bottom: 40px;
    border-radius: 5px;

    .filter-car {
        img {
            @media #{$vptabletP} {
                width: 100%;
            }
        }
    }

    .filter-car-content {
        padding: 15px 20px;
        width: 100%;

        @media #{$vptabletP} {
            padding: 30px;
        }

        @media #{$vpsmmobile} {
            padding: 15px 10px;
        }

        .filter-header {
            display: flex;
            justify-content: space-between;


            h4 {
                font-size: 26px;
                font-weight: 700;
                position: relative;
                text-transform: uppercase;
                margin-right: 40px;
                width: 100%;
                margin: 0;
                border-bottom: 1px solid $bdColor1;
                padding-bottom: 4px;

                @media #{$vpsmmobile} {
                    margin-right: 0;
                }

                @media #{$vpmobile} {
                    font-size: 22px;
                }

                @media #{$vpsmmobile} {
                    font-size: 18px;
                }

                @media #{$vpxsmobile} {
                    font-size: 15px;
                    padding-top: 7px;
                }

                // &::after {
                //     content: "";
                //     position: absolute;
                    
                //     width: 100%;
                //     left: 0;
                //     bottom: -5px;
                // }

                &.non-underline {
                    // &::after {
                    //     display: none;
                    // }
                    border-bottom: 0;
                }
            }
        }

        .filter-cont {
            padding: 15px 0;
        }

        h5 {
            &.renault {
                color: $carousel-btn-bg;
                font-weight: 700;
            }
            &.mt-15{
                margin-top: 15px;
            }
        }

        .option-section {
            margin-top: 10px;
            position: relative;
            margin-right: 30px;

            @media #{$vpsmmobile} {
                margin-right: 0;
            }

            &::after {
                content: "";
                position: absolute;
                border-bottom: 1px solid $bdColor1;
                width: 100%;
                left: 0;
                bottom: -15px;

            }

            .filter-car-box-options {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .options {
                    display: flex;
                    max-width: 37%;
                    flex: 0 0 37%;
                    padding-top: 20px;

                    .option-text {
                        margin-left: 10px;

                        h6 {
                            font-size: 16px;
                            font-weight: 800;
                            line-height: 28px;

                            @media #{$vpsmmobile} {
                                font-size: 13px;
                            }
                        }
                    }

                    &:last-child,
                    &:nth-child(2n+1) {
                        max-width: 23%;
                        flex: 0 0 23%;
                    }

                    &:nth-child(4n+1) {
                        max-width: 37%;
                        flex: 0 0 37%;
                    }

                    &:first-child,
                    &:nth-child(3n+1) {
                        max-width: 40%;
                        flex: 0 0 40%;
                    }

                    &:last-child {
                        margin-right: auto;
                    }

                    @media(max-width:767px) {
                        max-width: 50%;
                        flex: 0 0 50%;

                        &:last-child,
                        &:nth-child(2n+1) {
                            max-width: 50%;
                            flex: 0 0 50%;
                        }

                        &:nth-child(4n+1) {
                            max-width: 50%;
                            flex: 0 0 50%;
                        }

                        &:first-child,
                        &:nth-child(3n+1) {
                            max-width: 50%;
                            flex: 0 0 50%;
                        }
                    }

                    @media(max-width:399px) {
                        // max-width: 100%;
                        // flex: 0 0 100%;
                        // padding-top: 10px;

                        // &:last-child,
                        // &:nth-child(2n+1) {
                        //     max-width: 100%;
                        //     flex: 0 0 100%;
                        // }

                        // &:nth-child(4n+1) {
                        //     max-width: 100%;
                        //     flex: 0 0 100%;
                        // }

                        // &:first-child,
                        // &:nth-child(3n+1) {
                        //     max-width: 100%;
                        //     flex: 0 0 100%;
                        // }

                        .tosvg {
                            width: 28px;
                            height: 25px;
                        }
                    }
                }
            }
        }
    }
}

.circle-btn {
    position: relative;
    transition: all 0.3s ease;
    flex-shrink: 0;

    a,.round-r{
        display: block;
        width: 30px;
        border-radius: 50%;
        height: 30px;
        background-color: $carousel-btn-bg;
    }

    span {
        border-bottom: 2px solid #ffffff;
        width: 17px;
        height: 2px;
        left: 50%;
        top: 12px;
        transform: translate(-50%);
        position: absolute;

        &:nth-child(2) {
            top: 18px;
        }
    }

    &:hover {
        a,.round-r{
            background-color: $royal-blue;
        }
    }

    &.active-compare {
        span {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            position: absolute;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background-image: url('../img/icons/check-white.svg');
            background-repeat: no-repeat;
            background-size: 75%;
            display: inline-block;
            width: 30px;
            height: 30px;
            background-position: center;
        }
    }

}

.search-box {
    &.car-filter {
        margin-top: 0;

        .search-box-wrapper {
            padding: 40px 0 0 0;

            .form-search {
                h5 {
                    margin-bottom: 5px;

                    @media #{$vptabletP} {
                        font-size: 13px;
                    }
                }

                .select2-container--default {
                    .select2-selection--single {
                        height: 40px;
                        border: none;

                        .select2-selection__rendered {
                            line-height: 30px;
                        }

                        .select2-selection__placeholder {
                            color: $black;
                            font-size: 18px;

                            @media #{$vptabletP} {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .double-selection {
                    display: flex;
                    justify-content: space-between;

                    .select2-box {
                        width: 49%;
                    }
                }
            }
        }
    }
}

.services {
    .postBox {
        .postImg {
            margin-bottom: 30px;
        }
    }
}

.custom-pagination {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;

    &.pad-top {
        padding-top: 40px;
    }

    @media #{$vpmobile} {
        padding-top: 20px;
    }

    .prev-btn,
    .next-btn {
        .pagination {
            align-items: center;
            @media #{$vpmobile} {
                background-color: transparent;
                border: none;

                &:active {
                    background: transparent;
                }
            }

            .tosvg,img {
                margin-right: 10px;

                @media #{$vpmobile} {
                    width: 25px;
                }

                &.arrow-next {
                    margin-right: 0px;
                    margin-left: 10px;
                }

                @media #{$vpmobile} {
                    path {
                        fill: $carousel-btn-bg;
                        stroke: $carousel-btn-bg;
                    }
                }
            }

            span {
                @media #{$vpmobile} {
                    display: none;
                }
            }
        }
    }

    .page-numbering {
        display: flex;
        align-items: center;

        ul {
            li {
                margin-right: 20px;

                >span {
                    color: $white;
                    cursor: default;
                }

                @media #{$vptabletP} {
                    width: 30px;
                    height: 30px;
                    margin-right: 5px;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color: $black;
                    transition: all .35s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                &:hover {

                    &:nth-child(5) {
                        background-color: transparent;
                    }

                    a {
                        color: $white;
                        background-color: $carousel-btn-bg;
                    }
                }

                &.active {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: $carousel-btn-bg;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    a {
                        color: $white;
                        background-color: $carousel-btn-bg;
                        cursor: default;
                    }
                }
            }
        }
    }
}

.custom-select-box {
    p {
        margin-bottom: 10px;
    }

    h5 {
        margin-bottom: 10px;
    }

    .select2-container--default .select2-selection--single {
        border: 1px solid transparent;
        height: 41px;
        padding: 4px;
        border-radius: 5px;
        background-color: $cGray;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 35px;
    }

    &.sty-1 {
        margin-bottom: 20px;

        .select2-container--default .select2-selection--single {
            background-color: $white;
        }
    }
}