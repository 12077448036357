.stripe {
    .faq-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 11px;
        padding-bottom: 14px;

        .button {
            font-size: 18px;
            font-weight: 700;
            border: 0;
            padding: 0 18px;
            background-color: transparent;

            &:hover,
            &.is-checked {
                color: $cPrimary;
                text-decoration: underline;
                background-color: transparent;
            }

            @media #{$vptabletP} {
                font-size: 16px;
            }

            @media #{$vpmobile} {
                font-size: 14px;
            }
        }

        @media #{$vpmobile} {
            width: 660px;
        }
    }

    .faq-accordion {
        margin-top: 30px;

        .grid {
            height: auto !important;
            overflow: hidden;

            .js-isotopes {
                transition-property: left, top, opacity;
                transition-delay: 0s, 0.8s, 0s;
                transition: all .35s ease;
            }
        }

        .custom-card {
            margin-top: 10px;
            width: 100%;
            background-color: transparent;
            border: 0;
            position: relative !important;
            top: inherit !important;

            .card-header {
                &.custom-header {
                    background-color: $cPrimary;
                    border-radius: 12px 12px 0 0;
                    border: 0;
                    padding: 5px 20px;
                    cursor: pointer;

                    h5 {
                        color: $white;
                        font-size: 18px;
                        font-weight: 500;
                        text-decoration: none;
                        text-transform: none;
                        padding: 15px 25px;
                        cursor: pointer;
                        position: relative;

                        @media #{$vpmobile} {
                            font-size: 16px;
                        }

                        @media #{$vpsmmobile} {
                            font-size: 14px;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            background-image: url(../img/icons/arrow.png);
                            left: 0;
                            width: 13px;
                            height: 8px;
                            top: 24px;
                        }
                    }

                    &.collapsed {
                        background-color: $white;
                        border-radius: 12px;
                        border: 1px solid rgba($bdColor, .3);

                        h5 {
                            color: $black;

                            &:after {
                                background-image: url(../img/icons/arrow-black.png);
                            }
                        }
                    }
                }
            }

            .card-body {
                border-radius: 0 0 12px 12px;
                background-color: $white;
                padding: 15px 20px;
                border: 1px solid rgba($bdColor, .3);
                border-top: 0;

                p {
                    color: $cCharcoal;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media #{$vpmobile} {
            margin-top: 10px;
        }
    }
}