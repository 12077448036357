.footer {
    .footer-widget {
        background-color: rgba($color: $black, $alpha: .90);

        h3 {
            color: $white;
            margin-bottom: 110px;

            @media(max-width:991px) {
                margin-bottom: 30px;
            }
        }

        .flex {
            justify-content: flex-start;
            align-items: flex-start;
        }

        p {
            margin: 22px 0;
            font-size: 18px;
            font-weight: 400;
            color: $white;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .tosvg {
            width: 20px;
            height: 25px;
            margin-right: 20px;
            flex-shrink: 0;

            path {
                fill: $cPrimary !important;
            }
        }

        a:not(.btn) {
            display: inline-block;
            color: $white;

            &:hover {
                text-decoration: underline !important;
            }
        }

        .marktplatz-widget {
            .block {
                display: block;
            }

            p {
                padding-right: 30px;

                &:last-child {
                    padding-right: 80px;
                }
            }
        }

        .pt-md-40{
            padding-left: 30px;
        }

        @media(max-width:1199px) {
            .marktplatz-widget {
                padding-right: 50px;

                p {
                    padding-right: 0;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        @media(max-width:991px) {
            .marktplatz-widget {
                padding-right: 0;
            }
        }

        @media #{$vpmobile} {
            .pt-md-40{
                padding-left: 15px;
            }
        }

        @media #{$vpsmmobile} {
            h3 {
                font-size: 20px;
                margin-bottom: 25px;
            }

            p {
                margin: 10px 0;
                font-size: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .tosvg {
                width: 15px;
                margin-right: 10px;
            }
        }
    }

    .copy-right {
        background-color: $cCharcoal;
        padding: 14px 0;

        .flex {
            justify-content: space-between;
            align-items: center;
        }

        p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: $white;
        }

        a {
            color: $white;

            &:hover {
                text-decoration: underline !important;
            }
        }

        .copyright-link {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            li {
                margin-left: 55px;

                a {
                    font-size: 12px;
                    font-weight: 300;
                    display: block;
                }

                &:first-child {
                    margin-left: 0;
                }

                @media(max-width:575px){
                    margin-left: 20px;
                }
            }
        }

        @media #{$vpmobile} {
            .flex {
                display: block;
                text-align: center;
            }

            p {
                margin: 0 0 10px 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.on-tab {
            @media#{$vptabletP} {
                .flex {
                    display: block;
                    text-align: center;
                }

                p {
                    margin: 0 0 10px 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .copyright-link {
                    justify-content: center;
                }
            }
        }
    }
}