.bilder-hochladen {
    p {
        span {
            font-weight: 700;
        }
    }

    .photo-section {
        display: flex;
        margin: 40px 0;

        .main-photo {
            flex: 0 0 132px;
            max-width: 132px;
            height: 132px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .photo-text {
            margin: 0 30px 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .mainPhoto-text {
                @media #{$vpsmmobile} {
                    text-align: center;
                }
            }

            .link-text {
                display: flex;

                @media #{$vpsmmobile} {
                    justify-content: center;
                }

                ul {
                    display: flex;

                    li {
                        color: $cSecondary;
                        vertical-align: middle;
                        font-size: 18px;
                        font-weight: 700;
                        margin-left: 5px;

                        @media #{$vpmobile} {
                            font-size: 14px;
                        }

                        &:first-child {
                            margin-left: 0;
                        }

                        &::after {
                            content: "|";
                        }

                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }

                        a {
                            color: $cSecondary;

                            &:hover {
                                color: $carousel-btn-bg;
                            }
                        }


                    }
                }

            }
        }

        .check-box-select {
            &.sty-1 {
                flex: 0 0 350px;
                max-width: 350px;
                margin-left: auto;

                @media #{$vptabletL} {
                    flex: 0 0 210px;
                    max-width: 210px;
                }

                @media #{$vptabletP} {
                }

                @media #{$vpsmmobile} {
                    margin: auto;
                }

                .check-text {
                    @media #{$vpsmmobile} {
                        margin-left: 5px;
                    }

                }
            }
        }

        @media #{$vpmobile} {
            flex-direction: column;
            justify-content: center;

            .main-photo {
                height: auto;
                margin-bottom: 20px;
                flex: auto;
                max-width: 575px;
            }

            .photo-text {
                margin: 0 0 20px;
            }

            .check-box-select {
                &.sty-1 {
                    flex: auto;
                    margin-left: 0;
                }
            }
        }

        @media #{$vpsmmobile} {
            .photo-text {
                align-items: flex-start;
            }
        }
    }
}