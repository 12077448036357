.forgot-password {
    background-color: $white;
    padding: 50px 30px;
    border-radius: 12px;

    .form-group {
        p {
            margin-bottom: 10px;
        }
    }

    .forget-btn {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 30px;

        .btn-x-large {
            @media #{$vpmobile} {
                width: 100%;
            }
        }

        .btn-medium {
            font-size: 14px;
            font-weight: 500;
            background-color: $cGray;
            border-color: $cGray;
            color: $black;
            padding-top: 14px;
            padding-bottom: 14px;
            width: 126px;
            max-width: 100%;
            display: flex;
            align-items: center;

            .tosvg {
                margin-right: 5px;
            }

            @media #{$vptabletP} {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            @media #{$vpmobile} {
                width: 100%;
            }

            &:hover {
                background-color: $royal-blue;
                border-color: $royal-blue;
                color: $white;

                .tosvg {
                    path {
                        fill: $white;
                        stroke: $white;
                    }
                }
            }
        }

        .custom-btn {
            @media #{$vpmobile} {
                width: 100%;
            }
        }
    }
}