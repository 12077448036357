.subscribe-form {
    position: relative;

    input[type="text"],
    input[type="email"] {
        font-size: 14px;
        color: $cCharcoal;
        line-height: normal;
        font-weight: 400;
        width: 100%;
        padding: 10px 160px 10px 15px;
        border-radius: 8px 0px 0px 8px;
        height: 50px;
        text-align: left;
        border: 0;
    }

    .btn {
        background-color: $cPrimary;
        border-radius: 0px 8px 8px 0px;
        height: 50px;
        font-size: 22px;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        border: 0;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;

        &:hover {
            background-color: $cSecondary;
        }

        @media(max-width:1199px) {
            &:hover {
                background-color: $cPrimary;
            }
        }
    }

    @media #{$vpsmmobile} {

        input[type="text"],
        input[type="email"] {
            padding-right: 120px;
        }

        .btn {
            font-size: 16px;
        }
    }
}

.as-input {
    font-size: 18px;
    padding: 7px 15px;
    background-color: $cGray;
    font-weight: 400;
    line-height: 1.5;
    border-radius: .25rem;
    min-width: 150px;
    height: 41px;
}

.head-search {
    position: relative;
    min-width: 230px;
    border-top: 1px solid $bdColor;
    padding: 14px 10px 0 10px;
    z-index: 1;
    overflow: hidden;

    input[type="text"],
    input[type="search"] {
        font-size: 14px;
        color: $cCharcoal;
        background-color: $cGray;
        line-height: normal;
        font-weight: 400;
        width: 100%;
        padding: 10px 50px 10px 15px;
        border-radius: 8px 0px 0px 8px;
        height: 40px;
        text-align: left;
        border: 0;
    }

    .btn {
        background-color: $cPrimary;
        border-radius: 0px 8px 8px 0px;
        height: 40px;
        text-align: center;
        border: 0;
        position: absolute;
        right: 10px;
        top: 14px;
        margin-left: 0;
        line-height: 16px;

        .tosvg {
            width: 16px;
            height: 16px;

            path {
                fill: $white;
            }
        }

        &:hover {
            background-color: $cSecondary;
        }
    }
}

.form-search {
    @media #{$vptabletP} {
        padding-top: 20px;

        .select2-box {
            margin-bottom: 10px;
            .select2-container--default {
                .selectimg {
                    text-align: left;
                }
            }
            .cars-dropdown[data-placeholder-img]+.select2-container--default .select2-selection--single .select2-selection__placeholder{
                width: 100%;
            }
        }
    }

    .js-select2 {
        @media #{$vpmobile} {
            margin-top: 10px;
        }
    }
}

.form-msg {
    .alert {
        padding: 10px 15px;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        text-align: left;
        margin-bottom: 10px;

        .tosvg {
            margin-right: 10px;
            width: 14px;
            height: 14px;
        }

        strong {
            margin-right: 10px;
        }
    }

    .alert-danger {
        .tosvg {
            path {
                fill: #a94442;
            }
        }
    }

    .alert-success {
        .tosvg {
            path {
                fill: #3c763d;
            }
        }
    }
}

.form-control {
    height: 40px;
    border: 1px solid transparent;
    transition: border .2s linear;

    &:focus {
        box-shadow: none;
        border: 1px solid $royal-blue;
    }
    &.disabled,&[disabled=""],&[disabled="disabled"],&:disabled{
        color: #cccccc!important;
        cursor: not-allowed;
        box-shadow: none!important;
        background-color: #f5f5f5!important;
    }

}

.form-comment {
    background-color: $white;
    padding: 10px 20px;
    height: 360px;

    strong {
        display: block;
    }

    @media(max-width:991px) {
        height: auto;
        margin-bottom: 20px;
    }
}

.c-form {
    .form-group {
        margin-bottom: 20px;

        .form-control {
            border: 0;
            height: auto;
            font-size: 18px;
            padding: 7px 15px;
            background-color: $white;
            @include placeholder($dark-grey);

            &.text-box {
                height: 240px;
            }
        }
    }

    &.sty-1 {
        [data-filter] {
            display: none;

            &.is--active {
                display: block;
            }
        }

        .form-group {
            .form-control {
                background-color: $cGray;
            }
        }
    }
}

.custom-checkbox {
    position: relative;
    width: 15px;
    height: 15px;

    input {
        opacity: 0;
        width: 15px;
        height: 15px;
        position: absolute;

        &:checked,
        [checked="checked"] {
            ~.as-checkbox {
                border-color: $carousel-btn-bg;
                background-color: $carousel-btn-bg;

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &:hover {
        border-color: $cGray;
    }

    &:focus {
        border-color: $black;
    }

    .as-checkbox {
        border: 1px solid $black;
        width: 15px;
        height: 15px;
        border-radius: 4px;
        appearance: none;
        transition: all .35s ease;
        position: absolute;
        background-color: $white;

        &:after {
            content: "";
            background-image: url('../img/icons/check-white.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 15px;
            width: 100%;
            height: 100%;
            opacity: 0;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.custom-radio {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid $black;
    border-radius: 50%;

    input {
        opacity: 0;
        width: 14px;
        height: 14px;
        left: 0;
        top: 0;
        position: absolute;

        &:checked,
        [checked="checked"] {
            ~.as-radio {
                border-color: $black;
                background-color: $black;
            }
        }
    }

    &:hover {
        border-color: $black;
    }

    &:focus {
        border-color: $black;
    }

    .as-radio {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        appearance: none;
        transition: all .35s ease;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: $white;
    }
}

.step-req{
    color: $cPrimary;
    display: none;
    margin-top: 5px;
}

.invalid-field {
    .form-control {
        box-shadow: 0 0 0 1px $cPrimary !important;
    }

    input[type="checkbox"] {
        ~.as-radio {
            border-color: $cPrimary;
        }
    }

    .custom-radio {
        border-color: $cPrimary;
    }

    .step-req {
        display: inline;
        &.select-featured {
            display: none;
        }
    }

    .as-checkbox {
        border-color: $cPrimary;
    }

    &.custom-select-box {
        .select2-container--default {
            .select2-selection--single {
                border-color: $cPrimary;
            }
        }
    }

    .step-error {
        display: block;
    }

    &.is-uploadValid {
        .step-req {
            display: none;

            &.select-featured {
                display: inline;
            }
        }
    }
}

.step-error {
    color: $cPrimary;
    margin-top: 5px;
    font-size: 15px;
    margin-left: 2px;
    display: none;
}

.form-error {
    color: $cPrimary!important;
}

// for dashboard
.page-sidebar .sidebar-footer a {
    width: 100%;
    text-align: left;
    padding: 15px;
    font-size: 18px!important;
}