.filter-section {
    .arrow-next {
        margin: auto 5px;
    }
}

.add-car {
    .add-car-panel {
        margin-bottom: 40px;
    }
}

.c-popup {
    .c-form {
        &.sty-1 {
            .form-group {
                .form-check {
                    .custom-radio {
                        width: 16px;
                        height: 16px;
                        margin-top: 0;
                        margin-right: 0;
                    }

                }
            }
        }
    }
}