.car-overview-dropdown {
    border-bottom: 1px solid $bdColor1;

    &.border-none {
        border-bottom: none;
    }

    p {
        line-height: 50px;
    }

    @media(max-width:767px){
        p{
            margin-bottom: 0;
        }
        padding-bottom: 20px;
    }

    @media (max-width:575px){
        padding-bottom: 10px;

        p{
            margin-bottom: 0;
        }
    }
}

.vehicle-type {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -4px 40px;

    &-box{
        flex: 0 0 12.5%;
        max-width: 12.5%;
        margin: 0;
        cursor: pointer;
        position: relative;

        input[type=radio]{
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;

            &:checked,
            &[checked="checked"] {
                & ~ .postBox {
                    background-color: $cPrimary;
                    .postDes {
                        .contact-box-img {
                            .tosvg {
                                path,
                                rect {
                                    fill: $white;
                                }
                            }
                        }
                    }
                    p {
                        color: $white;
                    }
                }
            }
        }

        .postBox {
            margin: 4px;
            transition: all .35s ease;
        }

        @media(max-width:1199px){
            flex: 0 0 16.66%;
            max-width: 16.66%;
        }

        @media(max-width:991px){
            flex: 0 0 20%;
            max-width: 20%;
        }

        @media(max-width:767px){
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media(max-width:480px){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media(max-width:767px){
        display: none;
    }

}

.section-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    flex-wrap: wrap;

    @media #{$vpmobile} {
        flex-direction: column;
        justify-content: center;
    }

    .heading {
        margin: auto 0;

        p {
            color: $royal-blue;
            font-weight: 700;
            margin: auto;

            span {
                color: $carousel-btn-bg;
            }
        }
    }

    .postDes {
        .custom-btn {
            .btn {
                text-transform: none;
                padding: 5px 20px;

                .tosvg {
                    margin-left: 10px;
                }

            }

            span {
                vertical-align: middle;
            }
        }

        @media #{$vpmobile} {
            margin-top: 20px;
        }
    }

    .select2-box {
        &.custom-field {
            width: 255px;

            @media #{$vpmobile} {
                margin: 20px 0 0;
            }
        }

        .select2-container--default {
            .select2-selection--single {
                border: none;
                height: 40px;
                padding: 4px;
                background-color: $cGray;

            }

            .select2-selection__rendered {
                line-height: 30px;
            }

            .select2-selection__placeholder {
                color: $black;
                font-size: 18px;
            }

        }
    }
}

.filter-section {
    .custom-btn {
        a {
            svg {
                margin-right: 10px;

                &.tosvg {
                    width: 18px;
                    height: 20px;
                }
            }

            &:hover {

                svg {
                    path {
                        fill: $cSecondary;
                    }
                }

                span {
                    color: $cSecondary;
                }

            }
        }
    }

    .blank-background {
        span {
            vertical-align: middle;
        }
    }

    .fahzreugdaten-section {
        margin-top: 35px;

        .c-form.sty-1 .form-group .form-control {
            background-color: #ffffff;
        }
    }

}

.car-filteration {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media(max-width:991px) {
        flex-direction: column;
    }
}

.filter-car {
    height: 305px;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    flex: 0 0 504px;
    max-width: 504px;
    margin-right: 10px;

    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slick-slide {
        >div {
            height: 100%;
        }

        .car-slide {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @media(max-width:1199px) {
        flex: 0 0 400px;
        max-width: 400px;
    }

    @media(max-width:991px) {
        flex: 0 0 100%;
        max-width: 100%;
        border-radius: 5px 5px 0 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-right: 0;

    }

    @media(max-width:575px) {
        height: 250px;
    }
}