.partners {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .partner-logo {
        display: flex;
        align-items: center;
        flex: 0 0 20%;
        max-width: 20%;

        .tosvg {
            margin: auto;
        }
    }

    @media #{$vptabletP} {
        justify-content: center;

        .partner-logo {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }

    @media #{$vpmobile} {
        .partner-logo {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    @media #{$vpsmmobile} {
        .partner-logo {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}