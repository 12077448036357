.search-box {
    position: relative;
    margin-top: -185px;

    @media #{$vptabletP} {
        margin-top: 50px;
    }

    h3 {
        margin-bottom: 8px;
    }

    @media(min-width:1200px) {
        p {
            font-size: 20px;
        }
    }

    .search-btn-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 0;

        .search-text {
            position: relative;
            margin-right: 30px;
            font-size: $font-size-20;
            font-weight: 400;

            a {
                color: $cCharcoal;
            }

            &:hover {
                a {
                    color: rgba($cPrimary, 1);
                }

                &:after {
                    background-image: url(../img/icons/ic-dropdown-red.svg);
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: -14px;
                width: 12px;
                height: 6px;
                background-image: url(../img/icons/ic-dropdown.svg);
                background-size: 12px;
                display: inline-block;
                visibility: visible;
                transform: rotate(-90deg);
                transition: transform 0.2s linear;
                margin-top: -2px;
            }
        }

        @media #{$vpmobile} {
            justify-content: flex-start;
        }

        @media #{$vpsmmobile} {
            flex-direction: column;
            align-items: flex-start;

            .search-text {
                margin-bottom: 10px;
            }
        }
    }

    .search-box-wrapper {
        background-color: $cGray;
        border-radius: 12px;
        padding: 40px 50px;

        @media (max-width:991px){
            padding: 40px;
        }

        @media #{$vpmobile} {
            padding: 20px;

            .select2-box {
                margin-bottom: 20px;
            }
        }

        .search-result {
            span {
                color: $cPrimary;
            }
        }
    }
}

.fahzreugdaten-section,
.search-box .search-box-wrapper{
    .c-form{
        &.sty-1 {
            position: relative;

            .smloader {
                height: 50px;
                background-color: rgba($cGray,.9);
                display: none;

                &.ht-100 {
                    height: 100%;
                }
            }
        }
    }
}

.cars-dropdown {
    +.select2-container--default {
        .select2-selection--single {
            border: none;
            background-color: transparent;
            display: flex;
            justify-content: flex-end;
            padding: 0;

            .select2-selection__rendered {
                display: flex;
                justify-content: flex-end;
                color: $cPrimary;
                padding-right: 35px;
                font-size: 16px;
                font-weight: 400;
                width: 100%;
                line-height: 50px;
                padding-left: 0;

                .selectimg {
                    span {
                        max-width: 75%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                @media(max-width:575px){
                    justify-content: flex-start;
                }
            }

            .select2-selection__arrow {
                b {
                    &:after {
                        background-image: url(../img/icons/ic-dropdown-red.svg);
                    }
                }
            }

            .select2-selection__placeholder {
                color: $cPrimary;
            }
        }

        +.select2-container--default {
            .select2-results__option {
                padding: 6px 2px;
            }

            .select2-results__option[aria-selected=true] {
                background-color: transparent;
                color: $cPrimary;
            }

            .select2-results__option--highlighted[aria-selected] {
                background-color: $bdColor1;
                color: $cPrimary;
            }
        }
    }

    &[data-placeholder-img] {
        +.select2-container--default {
            .select2-selection--single {
                .select2-selection__placeholder {
                    position: relative;
                    display: flex;
                    align-items: center;
                    .placeholder-img{
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

// .js-select3img{
//     +.select2{
//         +.select2-container{
//             right: 0;
//             left: auto!important;
//             .select2-dropdown {
//                 max-width: 300px;
//             }
//         }
//     }
// }

// .js-select2img + .select2 + .select2-container .select2-dropdown {
//     max-width: 300px;
// }

// .js-select2img + .select2 + .select2-container {
//     right: 300px;
//     left: auto!important;
// }

.select2-container--default {
    .select2-selection--single {
        border: 1px solid rgba(82, 82, 82, .5);
        height: 50px;
        padding: 4px;
        border-radius: 8px;

        .select2-selection__rendered {
            color: $cCharcoal;
            line-height: 40px;
        }

        .select2-selection__arrow {
            height: calc(100% - 2px);
            width: 30px;

            b {
                border-width: 0;
                margin-top: 4px;

                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -5px;
                    margin-top: -5px;
                    width: 11px;
                    height: 6px;
                    background-image: url(../img/icons/ic-dropdown.svg);
                    background-size: 12px;
                    display: inline-block;
                    visibility: visible;
                    transform: rotate(0);
                    transition: transform 0.2s linear;
                }
            }
        }
    }

    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    &:after {
                        transform: rotate(180deg);

                    }
                }
            }
        }
    }

    .select2-results__option[aria-selected=true] {
        transition: background-color 0.2s, color 0.2s;
        background-color: transparent;
        color: $cPrimary;
    }

    .select2-results__option--highlighted[aria-selected] {
        transition: background-color 0.2s, color 0.2s;
        background-color: rgba($cPrimary, .5);
        color: $white;
    }

    .selectimg {
        line-height: 45px;
        width: 100%;
        text-align: right;

        img,
        svg {
            margin-right: 8px;
            max-width: 50px;
            max-height: 35px;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }

        @media (max-width:575px){
            text-align: left;
        }
    }
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-width: 0;
}

.select2-container--default:not(.select2) {
    .selectimg {
        line-height: 30px;
        display: flex;
        text-align: left;
        align-items: flex-start;
        padding: 0 10px;

        img,
        svg {
            vertical-align: top;
            flex-shrink: 0;
        }

        span {
            padding-top: 4px;
            vertical-align: top;
        }
    }
}

.select2-dropdown {
    border: none;
    border-top: 1px solid #707070;
    outline: none;

    &:after {
        content: "";
        z-index: -1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .05);
    }
}

.clasres {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 0s, 0s, .3s;
    -o-transition-delay: 0s, 0s, .3s;
    transition-delay: 0s, 0s, .3s;
    pointer-events: inherit;
}