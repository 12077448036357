.stripe {
    .error-page {
        p {
            font-size: 18px;
            margin-top: 90px;

            &.para {
                font-size: 14px;
                margin-top: 0;

                span {
                    color: $cPrimary;
                }
            }
        }

        .subscribe-form {
            width: 480px;
            margin: 0 auto;
            max-width: 100%;
            padding-left: 0;

            .btn {
                svg {
                    margin-right: 10px;

                    &.tosvg path {
                        fill: $white;
                    }
                }
            }
        }
    }
}